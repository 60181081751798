.scoreboard {
  text-align: center;
  padding: 20px;
}

table {
  width: 80%;
  margin: 20px auto;
  border-collapse: collapse;
}

table,
th,
td {
  border: 1px solid #333;
}

th,
td {
  padding: 10px;
  text-align: center;
}

th {
  background-color: #f2f2f2;
}

.buttons {
  margin-top: 20px;
}

button {
  margin: 0 10px;
  padding: 10px 20px;
  cursor: pointer;
}

.match-winner {
  font-size: 1.2em;
  font-weight: bold;
  color: green; /* You can change the color to your preference */
  margin-top: 20px;
}
